import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0,7,2,3,4];

export const dictionary = {
		"/": [~9],
		"/home": [~32],
		"/join/[groupcode]": [~33,[7],[8]],
		"/[code]": [~10,[2]],
		"/[code]/grp/create": [~17,[2]],
		"/[code]/grp/join": [~18,[2]],
		"/[code]/grp/[id]": [~11,[2,3]],
		"/[code]/grp/[id]/chat": [~12,[2,3]],
		"/[code]/grp/[id]/pairings": [13,[2,3]],
		"/[code]/grp/[id]/rcqst": [~14,[2,3]],
		"/[code]/grp/[id]/sqst": [~15,[2,3]],
		"/[code]/grp/[id]/wshlst": [~16,[2,3]],
		"/[code]/usr": [~19,[2]],
		"/[code]/usr/rcvr": [~20,[2]],
		"/[code]/wshlst/create": [28,[2,6]],
		"/[code]/wshlst/create/custom": [~29,[2,6]],
		"/[code]/wshlst/create/external": [~30,[2,6]],
		"/[code]/wshlst/create/simple": [~31,[2,6]],
		"/[code]/wshlst/[id]": [~21,[2,4]],
		"/[code]/wshlst/[id]/assign": [~23,[2,4]],
		"/[code]/wshlst/[id]/createEntry": [~24,[2,4]],
		"/[code]/wshlst/[id]/edit/custom": [~25,[2,4,5]],
		"/[code]/wshlst/[id]/edit/external": [~26,[2,4,5]],
		"/[code]/wshlst/[id]/edit/simple": [~27,[2,4,5]],
		"/[code]/wshlst/[id]/[entryId]/edit": [~22,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';